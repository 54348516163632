import React from "react";
import Topbar from "../Components/Topbar/Topbar";
import Enterance from "../Components/Enterance/Enterance";
import RedBar from "../Components/RedBar/RedBar";
import Sdk from "../Components/Sdk/Sdk";
import Capability from "../Components/Capability/Capability";
import UseCase from "../Components/UseCase/UseCase";
import Contact from "../Components/Contact/Contact";
import Certificate from "../Components/Certificate/Certificate";
import Footer from "../Components/Footer/Footer";
// import { Element } from "react-scroll";

function Main() {
  return (
    <div>
      <Topbar />
      <Enterance />
      <RedBar />
      <Capability />
      <Sdk />
      <UseCase />
      <div id="contactUs">
        <Contact />
      </div>
      <Certificate />
      <Footer />
    </div>
  );
}

export default Main;
