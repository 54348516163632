import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Papil11-logo.svg";
import "../style.css";

function Topbar() {
  return (
    <div id="topbar">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img src={Logo} className="logo" alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 p-3">
                <li className="nav-item active">
                  <Link to="/" className="nav-link">
                    Papil11
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/company" className="nav-link">
                    Company
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/askanExpert" className="nav-link">
                    Ask An Expert
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Topbar;
