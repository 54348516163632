import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "../style.css";

function Contact() {
  const form = useRef();
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dwiihn3",
        "template_0vq7l1l",
        form.current,
        "HaXKlXPCqbXXsOgHQ"
      )
      .then(
        (result) => {
          console.log(result.text);
          setAlertMessage("Form submitted successfully!");
          setIsAlertVisible(true);
          form.current.reset();
        },
        (error) => {
          console.error(error);
          setAlertMessage("An error occurred while submitting the form.");
          setIsAlertVisible(true);
        }
      );
  };

  useEffect(() => {
    if (isAlertVisible) {
      const timer = setTimeout(() => {
        setIsAlertVisible(false);
      }, 5000); // Hide the alert after 5 seconds (5000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timer when the component unmounts
      };
    }
  }, [isAlertVisible]);

  return (
    <div id="contact">
      <div className="row">
        <h3 className="mb-5">Contact Us</h3>
        {isAlertVisible && (
          <div
            className={`alert ${
              alertMessage.includes("successfully")
                ? "alert-success"
                : "alert-danger"
            }`}
          >
            {alertMessage}
          </div>
        )}
        <form ref={form} onSubmit={sendEmail}>
          <div className="row">
            <div className="col-6 mb-3">
              <label htmlFor="name" className="form-label">
                Name <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="user_name"
                required
              />
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="email" className="form-label">
                E-mail <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="user_email"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Your Message <span>*</span>
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                required
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                I agree to the <a href="/privacy-policy">Privacy Policy</a>
              </label>
            </div>
          </div>
          <button type="submit" className="send" value="Send">
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
