import React from "react";
import "../style.css";
import Icon1 from "../../assets/icon/icon1.svg";
import Icon2 from "../../assets/icon/icon2.svg";

function RedBar() {
  return (
    <div id="red-bar">
      <div className="row gy-3">
        <div className="col-md-6 col-sm-12">
          <div className="box d-flex align-items-center">
            <img src={Icon1} className="me-3" alt="" />
            <p className="mb-0">
              Feature vector extraction up to ~100 faces per second on single
              CPU core, and up 600 faces per second on GPU
            </p>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="box d-flex align-items-center">
            <img src={Icon2} className="me-3" alt="" />
            <p className="mb-0">
              Search/verification speed is 60 mln faces per second on CPU, and
              up to 800 mln faces per second on GPU
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedBar;
