import React from "react";
import "../style.css";
import Windows from "../../assets/icon/windows.svg";
import Linux from "../../assets/icon/linux.svg";
import Apple from "../../assets/icon/apple.svg";
import Android from "../../assets/icon/android.svg";
import Csharp from "../../assets/icon/cSharp.svg";
import C from "../../assets/icon/c.svg";
import Java from "../../assets/icon/java.svg";
import Python from "../../assets/icon/python.svg";
import Swift from "../../assets/icon/swift.svg";
import Flutter from "../../assets/icon/flutter.svg";

function Sdk() {
  return (
    <div id="sdk">
      <div className="row">
        <div className="col-md-6 ">
          <div className="d-flex align-items-center justify-content-around">
            <img src={Windows} alt="" />
            <img src={Linux} alt="" />
            <img src={Apple} alt="" />
            <img src={Android} alt="" />
          </div>
          <div>
            <h3 className="my-3">Supported Operating Systems</h3>
            <ul>
              <li>Windows x86 (32-bit, 64-bit)</li>
              <li>Linux x86 (32-bit, 64-bit)</li>
              <li>Linux ARM (32-bit, 64-bit)</li>
              <li>Android ARM (32-bit, 64-bit)</li>
              <li>iOS ARM (64-bit)</li>
            </ul>
          </div>
        </div>
        <hr className="d-md-none" />
        <div className="col-md-6">
          <div className="d-flex align-items-center justify-content-around">
            <img src={Csharp} alt="" />
            <img src={C} alt="" />
            <img src={Java} alt="" />
            <img src={Python} alt="" />
            <img src={Swift} alt="" />
            <img src={Flutter} alt="" />
          </div>
          <div>
            <h3 className="my-3">Available APIs</h3>
            <ul>
              <li>C++ API (for Windows, Linux, Android, iOS)</li>
              <li>Java wrapper (for Windows, Linux, Android)</li>
              <li>C# wrapper (for Windows, Linux)</li>
              <li>Python wrapper (for Windows, Linux)</li>
              <li>Flutter plugin (for iOS, Android)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sdk;
